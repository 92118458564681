:root {
  /* Common styles */
  --color-black: #000;
  --color-blue: rgba(58, 120, 255, 1);

  /* Background colors */
  --color-bg-main: transparent;
  --color-bg-primary: rgba(22, 27, 39, 1);
  --color-bg-active: rgba(32, 39, 56, 1);

  /* Typography colors */
  --color-text-main: rgba(255, 255, 255, 1);
  --color-text-secondary: rgba(169, 171, 199, 1);
  --color-text-inactive: rgba(161, 161, 161, 1);
  --color-text-red: rgba(233, 61, 102, 1);
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaRegularIt.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaRegularIt.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaRegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaRegular.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaRegular.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaSemiboldIt.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaSemiboldIt.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaSemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaSemibold.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaSemibold.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaSemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaBold.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaBold.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/proximaNova/proximaNovaBoldIt.woff2') format('woff2'),
    url('../fonts/proximaNova/proximaNovaBoldIt.woff') format('woff'),
    url('../fonts/proximaNova/proximaNovaBoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

body {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'ProximaNova', sans-serif;
  font-stretch: normal;
  font-style: normal;
  // background-color: var(--color-bg-main);
  background: url(../img/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

i {
  font-style: normal;
}
